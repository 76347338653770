import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./image-gallery.scss";

const ImageGallery = ({ urls, keyId }) => {
  const owlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    margin: 10,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      500: {
        items: 2,
      },
      768: {
        items: 3,
      },
    },
  };

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    setImageUrls(urls);

    return () => {
      setImageUrls([]);
    };
  }, [urls, keyId]);

  return (
    <div className="row m-0">
      <div className="col">
        {imageUrls.length > 0 && (
          <OwlCarousel
            key={`carousel_${imageUrls.length}_${keyId}`}
            className="owl-theme"
            {...owlOptions}
          >
            {console.log(`carousel_${imageUrls.length}_${keyId}`)}
            {imageUrls.map((url, i) => (
              <img src={url} key={`${keyId}_${i}`} alt="" />
            ))}
          </OwlCarousel>
        )}
      </div>
    </div>
  );
};

export default ImageGallery;
