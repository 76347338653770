/**
 * System Reducers
 */
import { combineReducers } from "redux";
import NotificationReducer from "./notification-reducer";
import ErrorNotificationReducer from "./error-notification-reducer";

/**
 * System functionalities
 */
const SystemReducer = combineReducers({
  notification: NotificationReducer,
  errorNotification: ErrorNotificationReducer,
});

export default SystemReducer;
