import React, { useState, useCallback } from "react";
import { Row, Col, CardImg, Card } from "reactstrap";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import ImgsViewer from "react-images-viewer";
import "./gallery.scss";
import galleryItems from "./gallery-items";

const Gallery = ({ title }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imageCount, setImageCount] = useState(0);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setImageCount(getFlattenedImageArray().length);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const onNextImage = () => {
    let newIndex = currentImage + 1;
    setCurrentImage(newIndex >= imageCount ? 0 : newIndex);
  };

  const onPreviousImage = () => {
    let newIndex = currentImage - 1;
    setCurrentImage(newIndex < 0 ? imageCount - 1 : newIndex);
  };

  const getFlattenedImageArray = () => {
    let images = [];

    galleryItems.forEach(function (section) {
      section.items.forEach(function (item) {
        images.push({
          src: `https://solvable.azureedge.net/horizon-sails/images/${item.imageName}`,
          caption: item.title,
        });
      });
    });

    return images;
  };

  return (
    <EnhancedContentSection
      isBlocking={false}
      showPageTitle={true}
      pageTitle={"Gallery"}
      bgClass="sw-bg-std-grey"
    >
      {galleryItems.map((section, i) => (
        <div key={i}>
          <h4>{section.section}</h4>

          <Row className="gallery mt-3 mb-4">
            {section.items.map((x, index) => {
              return (
                <Col
                  lg="4"
                  sm="6"
                  xs="12"
                  key={index}
                  className="mb-4 img-itm"
                  onClick={() => openImageViewer(x.imageNumber)}
                >
                  <Card className="shadow">
                    <CardImg
                      top
                      width="100%"
                      src={`https://solvable.azureedge.net/horizon-sails/images/${x.imageName}`}
                      alt={x.title}
                      className="img"
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      ))}

      <ImgsViewer
        imgs={getFlattenedImageArray()}
        currImg={currentImage}
        isOpen={isViewerOpen}
        onClickPrev={onPreviousImage}
        onClickNext={onNextImage}
        onClose={closeImageViewer}
      />
    </EnhancedContentSection>
  );
};

export default Gallery;
