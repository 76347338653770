// type imr and press tab for   import React from 'react';
import React from "react";
import ImageHeaderSecondary from "components/common/image-header-secondary";
import ContentSection from "components/common/content-section/index";
import ImageGallery from "components/common/image-gallery";

// type sfc and press tab for  the function body - don't forget to give it a name (i.e. CaseStudy)

const CaseStudy = ({ heading, mainImage, imageGalleryUrls, children }) => {
  return (
    <>
      <ImageHeaderSecondary imgSrc={mainImage} />
      <ContentSection>
        <h2 className="mb-3 text-center text-uppercase">{heading}</h2>
        <h4 className="mb-3 text-center text-uppercase">
          A case study by Horizon Sails &amp; Membranes
        </h4>
        {children}
        <h4>View the Gallery</h4>
        <ImageGallery
          key={`gallery_${heading}`}
          urls={imageGalleryUrls}
          keyId={heading}
        />
      </ContentSection>
    </>
  );
};

export default CaseStudy;
