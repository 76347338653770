import React from "react";

const View = ({ image }) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a img src={`${image.url}`}>
      <div
        className="home-partner"
        style={{
          backgroundImage:
            "url('https://solvable.azureedge.net/horizon-sails/partners/" +
            image.name +
            "')",
          cursor: "pointer",
        }}
      ></div>
    </a>
  );
};

export default View;
