import React from "react";
import { Link } from "react-router-dom";
import ImageHeader from "./../../components/common/image-header";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import ContentSection from "../../components/common/content-section";
import Partner from "./components/partner";
import PageTagging from "components/common/page-tagging/page-tagging";

const Home = () => {
  const solutions = [
    {
      heading: "Shade Sail Structures",
      description:
        "Commercial steel structure with superior ​protection from the elements.",
      linkUrl: "/shade-sail-structures/",
      imageUrl:
        "https://solvable.azureedge.net/horizon-sails/images/shade-structure-17.jpg",
    },
    {
      heading: "PVC Membranes",
      description:
        "Practical, Cost effective and creative tensioned shade applications.",
      linkUrl: "/pvc-membranes/",
      imageUrl:
        "https://solvable.azureedge.net/horizon-sails/images/membrane-main.jpg",
    },
    {
      heading: "Resort Umbrellas",
      description:
        "Superior quality resort solutions designed to meet your requirements.",
      linkUrl: "/resort-umbrellas/",
      imageUrl:
        "https://solvable.azureedge.net/horizon-sails/images/resort-umbrella-main.jpg",
    },
  ];

  return (
    <>
      <ImageHeader imgSrc="https://solvable.azureedge.net/horizon-sails/images/home.jpg" />
      <EnhancedContentSection isBlocking={false} showPageTitle={true}>
        <PageTagging
          title="Horizon Sails &amp; Membranes | Commercial &amp; Residential Shade Solutions"
          description="Horizon Sails &amp; Membranes specialise in commercial shade solutions including Shade Sails, PVC Membrane Structures and Resort Umbrellas"
        />
        <h2 className="mb-4 text-center text-uppercase">
          Horizon Sails &amp; Membranes - Our Company
        </h2>
        <div className="row">
          <div className="col-md-6 col-12">
            <p>
              For over 30 years, Horizon Sails &amp; Membranes has forged a
              great reputation as designers, manufacturers and installers of
              quality commercial grade shade sails, PVC membrane structures and
              resort umbrellas.
            </p>
            <p>
              This reputation has seen our company grow to become the preferred
              supplier to large development companies, restaurants &amp; cafes,
              Australia's biggest theme parks, shopping centres and
              international &amp; domestic airports.
            </p>
            <p>
              Our work speaks for itself and can be seen on some of Australia's
              greater icons. This proud history puts us at the forefront of our
              industry with annual awards and commendations a normal part of our
              year.
            </p>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img
              src="https://solvable.azureedge.net/horizon-sails/images/shade-structure-24.jpg"
              alt=""
            />
          </div>
        </div>
      </EnhancedContentSection>
      <ContentSection bgClass="sw-bg-std-grey">
        <h2 className="mb-4 text-center text-uppercase">
          What can we deliver for you
        </h2>
        <p className="text-center mb-4">
          Horizon Sails &amp; Membranes specialise in commercial shade solutions
          including Shade Sails, PVC Membrane Structures and Resort Umbrellas
          which provide protection from the harsh Australian environment. When
          you entrust your commercial shade project to Horizon Sails &amp;
          Membranes, you know that your investment is in safe, experienced
          hands.
        </p>
        <div className="row form-row">
          {solutions.map((solution, i) => (
            <div key={i} className="col-md-4 col-12 text-center mb-3">
              <h4 className="mb-3">{solution.heading}</h4>
              <p className="mb-3">{solution.description}</p>
              <Link to={solution.linkUrl}>
                <img src={solution.imageUrl} alt="" />
              </Link>
              <Link
                to={solution.linkUrl}
                className="mt-blue-mini-button mt-3 btn btn-secondary"
              >
                Learn More
              </Link>
            </div>
          ))}
        </div>
      </ContentSection>
      <ContentSection bgClass="sw-bg-std-blue">
        <h2 className="mb-4 text-center text-white text-uppercase">
          The Horizon Difference
        </h2>
        <h4 className="text-white text-center mb-5">
          Horizon Sails &amp; Membranes is one only a handful of manufacturers
          of shade sail structures in Australia. Our superior shade solutions
          are designed, manufactured and installed by our IN - HOUSE team from
          our manufacturing facility on the Gold Coast in Queensland.
        </h4>
        <div className="row text-center">
          <div className="col-md-3 col-12">
            <div className="icon-container mb-4">
              <i class="fal fa-drafting-compass" />
            </div>
            <h2 className="text-white text-uppercase mb-3">Design</h2>
            <p className="text-white">
              Design and Pattern by experienced personnel using the latest CAD
              techniques.
            </p>
          </div>
          <div className="col-md-3 col-12">
            <div className="icon-container mb-4">
              <i class="fal fa-wrench" />
            </div>
            <h2 className="text-white text-uppercase mb-3">Manufacture</h2>
            <p className="text-white">
              Manufactured by specialised fabricators using computer guided
              plotter/cutters, high frequency welders &amp; other state of the
              art machinery.
            </p>
          </div>
          <div className="col-md-3 col-12">
            <div className="icon-container mb-4">
              <i class="fal fa-user-hard-hat" />
            </div>
            <h2 className="text-white text-uppercase mb-3">Install</h2>
            <p className="text-white">
              Installation by qualified and experienced shade sail riggers.
            </p>
          </div>
          <div className="col-md-3 col-12">
            <div className="icon-container mb-4">
              <i class="fal fa-file-certificate" />
            </div>
            <h2 className="text-white text-uppercase mb-3">Certification</h2>
            <p className="text-white">
              Certification by registered structural engineers.
            </p>
          </div>
        </div>
      </ContentSection>
      <ContentSection>
        <h2 className="mb-4 text-center text-uppercase">
          Our experience counts
        </h2>
        <p className="mb-4 text-center">
          For over 30 years, we have developed technologies and processes that
          put us ahead of others in our industry. As thought leaders and
          innovators in our industry, we continue to do what other competitors
          can only aspire to. At Horizon Sails &amp; Membranes, it's the things
          that make us different that ensure our success.
        </p>
        <div className="row">
          <div className="col-md-6 col-12">
            <h4>What makes us different to our competitors?</h4>
            <p>
              At Horizon Sails &amp; Membranes, it's the things that make us
              different that leads to your success. We have worked hard since
              1983 to establish ourselves as a leading supplier of Shade Sail
              Structures, PVC Membrane Structures and Resort Umbrellas.
            </p>
            <p>
              This growth and our reputation as a professional strategic partner
              have come from listening to our customers, understanding their
              requirements and doing things well. Management has a hands on
              approach to quality control and risk reduction. Your project is
              professionally managed from design and engineering, through to
              manufacture and installation - always in close consultation and
              communication with the client.
            </p>
            <p>
              Our suppliers include multinational brand names like Serge
              Ferrari, Mehler Technologies, Rainbow Shade and Gale Pacific.
            </p>
            <p>
              The projects on which we built our reputation stand as a testament
              to the quality of our design and standard of workmanship borne out
              by the number of prestigious customers with whom we have had
              dealings with over the years and who continue to return to us for
              new projects.
            </p>
            <h4>
              "We build superior shade structures which stand the test of time"
            </h4>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img
              src="https://solvable.azureedge.net/horizon-sails/images/shade-structure-22.jpg"
              alt=""
              className="mb-3"
            />
            <img
              src="https://solvable.azureedge.net/horizon-sails/images/shade-structure-4.jpg"
              alt=""
            />
          </div>
        </div>
      </ContentSection>
      <ContentSection bgClass="sw-bg-std-blue">
        <h2 className="mb-4 text-center text-white text-uppercase">
          Partner with us
        </h2>
        <h4 className="text-white text-center">
          Having the right partner in your next shade structure project is
          crucial to your success.
        </h4>
        <h4 className="text-white text-center mb-4">
          When you partner with the team at Horizon Sail Structures, ​we share
          our knowledge, experience and expertise with you to deliver for your
          company or your clients.
        </h4>
      </ContentSection>
      <Partner />
    </>
  );
};

export default Home;
