const galleryItems = [
  {
    section: "Domestic",
    items: [
      { imageNumber: 0, imageName: "umbrella-1.jpg", title: "" },
      { imageNumber: 1, imageName: "umbrella-2.jpg", title: "" },
      { imageNumber: 2, imageName: "umbrella-3.jpg", title: "" },
      { imageNumber: 3, imageName: "umbrella-4.jpg", title: "" },
      { imageNumber: 4, imageName: "umbrella-5.jpg", title: "" },
      { imageNumber: 5, imageName: "umbrella-6.jpg", title: "" },
      {
        imageNumber: 6,
        imageName: "Sovereign Island Umbrella.jpg",
        title: "Sovereign Island Umbrella",
      },
      {
        imageNumber: 7,
        imageName: "Versace Penthouse.jpg",
        title: "Versace Penthouse",
      },
      {
        imageNumber: 8,
        imageName: "Commodore Dve Surfers Paradise.jpg",
        title: "Commodore Dve Surfers Paradise",
      },
      {
        imageNumber: 9,
        imageName: "Sanctuary Cove Umbrella.jpg",
        title: "Sanctuary Cove Umbrella",
      },
      {
        imageNumber: 10,
        imageName: "Norsman Dve Surfers Paradise.jpg",
        title: "Norsman Dve Surfers Paradise",
      },
      {
        imageNumber: 11,
        imageName: "Motorhome Cover.JPG",
        title: "Motorhome Cover",
      },
    ],
  },
  {
    section: "Car Parks",
    items: [
      { imageNumber: 12, imageName: "carpark-1.jpg", title: "IGA Gladstone" },
      {
        imageNumber: 13,
        imageName: "carpark-2.jpg",
        title: "Tree Tops Shopping Centre",
      },
      { imageNumber: 14, imageName: "carpark-3.jpg", title: "" },
      { imageNumber: 15, imageName: "carpark-4.jpg", title: "" },
      {
        imageNumber: 16,
        imageName: "carpark-5.jpg",
        title: "Goonellabah Shopping Centre",
      },
      { imageNumber: 17, imageName: "carpark-6.jpg", title: "" },
      { imageNumber: 18, imageName: "carpark-7.jpg", title: "" },
      { imageNumber: 19, imageName: "carpark-8.jpg", title: "Coles Ipswich" },
      { imageNumber: 20, imageName: "carpark-9.jpg", title: "Boyne Plaza" },
      { imageNumber: 21, imageName: "carpark-10.jpg", title: "" },
      { imageNumber: 22, imageName: "carpark-11.jpg", title: "" },
      { imageNumber: 23, imageName: "carpark-12.jpg", title: "" },
      { imageNumber: 24, imageName: "carpark-13.jpg", title: "GC Airport" },
      { imageNumber: 25, imageName: "carpark-14.jpg", title: "" },
      {
        imageNumber: 26,
        imageName: "Vicinity Grand Plaza.jpg",
        title: "Vicinity Grand Plaza",
      },
      {
        imageNumber: 27,
        imageName: "Woolworths Ooralea.jpg",
        title: "Woolworths Ooralea",
      },
      {
        imageNumber: 28,
        imageName: "Gold Coast Airport Entrance.jpg",
        title: "Gold Coast Airport Entrance",
      },
      { imageNumber: 29, imageName: "RTC.jpg", title: "" },
    ],
  },
  {
    section: "Restaurants",
    items: [
      { imageNumber: 30, imageName: "georges-1.jpg", title: "" },
      {
        imageNumber: 31,
        imageName: "georges-2.jpg",
        title: "Georges Paragon Restaurant",
      },
      {
        imageNumber: 32,
        imageName: "georges-3.jpg",
        title: "Georges Paragon Restaurant",
      },
      {
        imageNumber: 33,
        imageName: "georges-4.jpg",
        title: "Georges Paragon Restaurant",
      },
      { imageNumber: 34, imageName: "georges-5.jpg", title: "" },
      {
        imageNumber: 35,
        imageName: "Georges Paragon Restaurant 6.jpg",
        title: "Georges Paragon Restaurant",
      },
      {
        imageNumber: 36,
        imageName: "Sanctuary Cove Restaurant.jpg",
        title: "Sanctuary Cove Restaurant",
      },
      {
        imageNumber: 37,
        imageName: "Bada Bing Restaurant.jpg",
        title: "Bada Bing Restaurant",
      },
      {
        imageNumber: 38,
        imageName: "Omeros Brothers Marina Mirage.jpg",
        title: "Omeros Brothers Marina Mirage",
      },
    ],
  },
  {
    section: "Clubs, Theme Parks, Recreation",
    items: [
      { imageNumber: 39, imageName: "sea-world-1.jpg", title: "" },
      { imageNumber: 40, imageName: "sea-world-2.jpg", title: "" },
      {
        imageNumber: 41,
        imageName: "sea-world-3.jpg",
        title: "Seaworld Jet Rescue",
      },
      { imageNumber: 42, imageName: "sea-world-4.jpg", title: "Movie World" },
      {
        imageNumber: 43,
        imageName: "Byron Bay Hospital.jpg",
        title: "Byron Bay Hospital",
      },
      { imageNumber: 44, imageName: "GCCC.jpg", title: "GCCC" },
      {
        imageNumber: 45,
        imageName: "Gold Coast Holiday Park.jpg",
        title: "Gold Coast Holiday Park",
      },
      {
        imageNumber: 46,
        imageName: "Mossman Bowls Club.jpg",
        title: "Mossman Bowls Club",
      },
      {
        imageNumber: 47,
        imageName: "Sheraton Grand Gold Coast.jpg",
        title: "Sheraton Grand Gold Coast",
      },
    ],
  },
  {
    section: "Schools",
    items: [
      {
        imageNumber: 48,
        imageName: "my-cubby-house-1.jpg",
        title: "My Cubby House",
      },
      {
        imageNumber: 49,
        imageName: "my-cubby-house-2.jpg",
        title: "My Cubby House",
      },
      {
        imageNumber: 50,
        imageName: "my-cubby-house-3.jpg",
        title: "My Cubby House",
      },
      {
        imageNumber: 51,
        imageName: "my-cubby-house-4.jpg",
        title: "My Cubby House",
      },
      {
        imageNumber: 52,
        imageName: "All Saints College.jpg",
        title: "All Saints College",
      },
      {
        imageNumber: 53,
        imageName: "Barrell Vault.jpg",
        title: "Barrell Vault",
      },
      {
        imageNumber: 54,
        imageName: "Griffith University.jpg",
        title: "Griffith University",
      },
      { imageNumber: 55, imageName: "Hopscotch.jpg", title: "Hopscotch" },
      {
        imageNumber: 56,
        imageName: "Marrymount College.jpg",
        title: "Marrymount College",
      },
    ],
  },
  {
    section: "Commercial Premises",
    items: [
      { imageNumber: 57, imageName: "Oracle 2.jpg", title: "Oracle" },
      { imageNumber: 58, imageName: "Oracle.jpg", title: "Oracle" },
      {
        imageNumber: 59,
        imageName: "Pivotal Point Southport.jpg",
        title: "Pivotal Point Southport",
      },
      {
        imageNumber: 60,
        imageName: "Sawtell Truck Yard.jpg",
        title: "Sawtell Truck Yard",
      },
      { imageNumber: 61, imageName: "office-shade.jpg", title: "" },
      {
        imageNumber: 62,
        imageName: "Caliope Bus Stop.jpg",
        title: "Caliope Bus Stop",
      },
      {
        imageNumber: 63,
        imageName: "Gold Coast  Helitours Hanger.jpg",
        title: "Gold Coast  Helitours Hanger",
      },
      {
        imageNumber: 64,
        imageName: "Marina Mirage.jpg",
        title: "Marina Mirage",
      },
      { imageNumber: 65, imageName: "membrane-1.jpg", title: "" },
    ],
  },
];

export default galleryItems;
