import React from "react";
//import { Link } from "react-router-dom";
//import ContentSection from "../components/common/content-section";

export const corePublicPages = [
  {
    pageName: "get-in-touch",
    title: "CONTACT US",
    description: "",
    content: (
      <>
        <p>
          Our professional team will handle every stage of your new shade
          project starting with your initial call or email.
        </p>
        <p>
          Feel free to contact us now. (After hours pls use our form &amp; we
          will contact you at the start of the next business day.)
        </p>
      </>
    ),
  },
  {
    pageName: "privacy-policy",
    title: "Privacy Policy",
    description: "",
    content: <></>,
  },
  {
    pageName: "not-found",
    title: "Page Not Found",
    description: "",
    content: (
      <>
        <h1>Page Not Found</h1>
      </>
    ),
  },
];
