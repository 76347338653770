import React from "react";

class Error500 extends React.Component {
  render() {
    return (
      <div className="error-box">
        <div
          className="error-body text-center"
          style={{ maxWidth: 800, margin: "0 auto" }}
        >
          <div className="mt-4">
            <h3 style={{ lineHeight: "1.5em" }}>Good on ya, you broke it!</h3>
            <h5 className="mb-0 text-muted font-medium">
              Just kidding, looks like it might be our fault and we have
              something to fix.
            </h5>
            <h4 className="mt-4 mb-0 text-muted font-medium">
              Please{" "}
              <a href="/">
                click here to reload the Horizon Sails &amp; Membranes Website
              </a>
              .
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

export default Error500;
