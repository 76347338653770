import React, { Component } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Row,
  Col,
} from "reactstrap";
import "./nav-bar.scss";
import ButtonFx from "./../common/button-fx";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.navigate = this.navigate.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    var self = this;
    $(document).click(function (event) {
      let target = $(event.target);
      let mobileMenuOpen = $(".navbar-collapse").hasClass("show");
      let navLink = target.hasClass("nav-link");
      let navItem = target.hasClass("nav-item");
      let navBarNavItem = target.hasClass("navbar-nav-item");
      let navBarCollapse = target.hasClass("navbar-collapse");
      let navBarNav = target.hasClass("navbar-nav");
      let hasNav =
        navLink || navItem || navBarNavItem || navBarCollapse || navBarNav;
      if (mobileMenuOpen && !hasNav) {
        self.closeMenu();
        return false;
      }

      if (navLink && target[0].nextSibling === null) {
        self.closeMenu();
        return false;
      }

      if (mobileMenuOpen && navLink) {
        let dropDownAttach =
          target[0] &&
          target[0].nextSibling &&
          target[0].nextSibling &&
          !target[0].nextSibling.className.includes("show");
        if (dropDownAttach) {
          self.closeMenu();
          return false;
        }
      }

      // Highlighted Items
      // if (
      //   mobileMenuOpen &&
      //   (target[0].text === "My Membership")
      // ) {
      //   $(".navbar-collapse").toggle(false);
      //   return false;
      // }
    });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  closeMenu() {
    this.setState({
      isOpen: false,
    });
  }

  openMenu() {
    this.setState({
      isOpen: true,
    });
  }

  navigate(path) {
    this.props.history.push(path);
  }

  getNavItem = (navItem, i) => {
    if (
      navItem.subLinks &&
      navItem.subLinks.length > 0 &&
      navItem.highlightClass
    ) {
      return (
        <UncontrolledDropdown
          key={i}
          className={navItem.highlightClass}
          nav
          inNavbar
        >
          <DropdownToggle
            id="main-navigation"
            href="#main-navigation"
            aria-controls="main-navigation"
            onClick={() => {
              this.navigate(navItem.href);
            }}
            nav
          >
            {navItem.title.replace(/ /g, "\u00a0")}
          </DropdownToggle>
          {false && (
            <DropdownMenu right>
              {navItem.subLinks.map((subItem, a) => (
                <DropdownItem
                  key={a}
                  tag={RRNavLink}
                  to={subItem.href}
                  onClick={this.closeMenu}
                >
                  {subItem.title}
                </DropdownItem>
              ))}
            </DropdownMenu>
          )}
        </UncontrolledDropdown>
      );
    } else if (navItem.subLinks && navItem.subLinks.length > 0) {
      return (
        <UncontrolledDropdown key={i} nav inNavbar>
          <DropdownToggle
            onClick={() => {
              this.navigate(navItem.href);
            }}
            nav
          >
            {navItem.title.replace(/ /g, "\u00a0")}
          </DropdownToggle>
          <DropdownMenu right className=".drop dropdown-attach-menu-to-parent">
            {navItem.subLinks.map((subItem, a) => (
              <DropdownItem
                key={a}
                tag={RRNavLink}
                to={subItem.href}
                onClick={this.closeMenu}
              >
                {subItem.title}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    } else if (navItem.highlightClass) {
      return (
        <NavItem key={i} className={navItem.highlightClass}>
          <NavLink tag={RRNavLink} to={navItem.href}>
            {navItem.title.replace(/ /g, "\u00a0")}
          </NavLink>
        </NavItem>
      );
    } else if (navItem.href.startsWith("http")) {
      return (
        <NavItem key={i}>
          <a
            href={navItem.href}
            className="nav-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {navItem.title.replace(/ /g, "\u00a0")}
          </a>
        </NavItem>
      );
    } else {
      return (
        <NavItem key={i}>
          <NavLink tag={RRNavLink} to={navItem.href}>
            {navItem.title.replace(/ /g, "\u00a0")}
          </NavLink>
        </NavItem>
      );
    }
  };

  render() {
    const { navLinks } = this.props;
    return (
      <Navbar
        light={false}
        dark={false}
        expand="md"
        className="navbar-default sticky-top sticky-nav navbar-bg d-flex flex-column"
      >
        <div className="top-header w-100 d-flex">
          <Container fluid className="container-style py-3 d-none d-lg-block">
            <Row className="d-flex justify-content-between align-items-center">
              <Col xs="6" className="text-white">
                <Row className="d-flex justify-content-start">
                  <div className="mr-4">
                    <a href="tel:0755631433">
                      Call us for a Quote: 07 5563 1433
                    </a>
                  </div>
                  <div>
                    <Row>
                      <Col xs="12" md="6" xl="2">
                        <div className="d-flex flex-row social-wrapper">
                          {/* <div className="social-container flex justify-content-center align-items-center">
                            <a
                              href="https://www.facebook.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-facebook-f social-icon" />
                            </a>
                          </div>
                          <div className="social-container">
                            <a
                              href="https://www.linkedin.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-linkedin social-icon" />
                            </a>
                          </div> */}
                          {/* <div className="social-container">
                            <i className="fab fa-instagram social-icon" />
                          </div> */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Row>
              </Col>
              <Col xs="6" className="d-flex justify-content-end">
                <ButtonFx
                  className="mt-aqua-mini-button mr-2"
                  onClick={() => this.navigate("/contact-us/")}
                >
                  <i className="fas fa-envelope mr-1" /> Contact Us
                </ButtonFx>
              </Col>
            </Row>
          </Container>
        </div>

        <Container fluid className="container-style">
          <NavbarBrand href="/">
            <img
              src="/images/horizon-sails-logo.png"
              alt="Horizon Sails &amp; Membranes Pty Ltd"
            />
          </NavbarBrand>
          <NavbarToggler
            className="navbar-toggler"
            onClick={() => {
              this.toggle();
            }}
          >
            <span className="sr-only">Toggle navigation</span>{" "}
            <i className="fas fa-bars"></i>
          </NavbarToggler>
          <Collapse isOpen={this.state.isOpen} navbar timeout={100}>
            <Nav navbar>
              {navLinks.map((navItem, i) => this.getNavItem(navItem, i))}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default withRouter(NavBar);
