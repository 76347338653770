import React from "react";

class Error404 extends React.Component {
  render() {
    return (
      <div className="error-box">
        <div
          className="error-body text-center"
          style={{ maxWidth: 800, margin: "0 auto" }}
        >
          <div className="mt-4">
            <h3 style={{ lineHeight: "1.5em" }}>
              Damn, we can&rsquo;t find what you&rsquo;re looking for.
            </h3>
            <h4 className="mt-4 mb-0 text-muted font-medium">
              Please{" "}
              <a href="/">
                click here to reload the Horizon Sails &amp; Membranes Website
              </a>
              .
            </h4>
            <h5 className="text-muted font-medium mt-4">
              If you really need whatever this was supposed to be, please
              contact the team.
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

export default Error404;
