import Playground from "views/playground";
import Home from "views/home";
import ContactUs from "views/contact-us";
import ContentPage from "views/content-page";
import Gallery from "views/gallery";

const ModuleRoutes = [
  // Test Routes
  {
    path: "/playground/:path",
    name: "Playground",
    component: Playground,
    privateRoute: false,
  },
  // Core Routes
  {
    path: "/",
    name: "Home",
    component: Home,
    privateRoute: false,
  },
  {
    path: "/gallery/",
    name: "Gallery",
    component: Gallery,
    privateRoute: false,
  },
  {
    path: "/contact-us/",
    name: "Contact Us",
    component: ContactUs,
    privateRoute: false,
  },
  // User Routes

  // Catch all Route
  {
    path: "*",
    component: ContentPage,
    privateRoute: false,
  },
];

export default ModuleRoutes;
