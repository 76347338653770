import React from "react";
import "./image-header-secondary.scss";
import { PropTypes } from "prop-types";

const ImageHeaderSecondary = ({ imgSrc, title }) => {
  return (
    <header className="image-header-secondary">
      <div
        style={{ backgroundImage: "url(" + imgSrc + ")" }}
        className="image-container d-flex flex-column justify-content-center align-items-center"
      ></div>

      <div className="text-container w-100 d-flex flex-column justify-content-center align-items-center">
        <h1>{title}</h1>
      </div>
    </header>
  );
};

ImageHeaderSecondary.propTypes = {
  title: PropTypes.string,
  imgSrc: PropTypes.any.isRequired,
};

export default ImageHeaderSecondary;
