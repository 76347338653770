import React from "react";
import ContentSection from "components/common/content-section";
import Partners from "components/common/partners";
import "./partner.scss";

const Partner = () => {
  return (
    <div className="partner">
      <ContentSection>
        <div className="w-100 text-center">
          <h2 className="mb-4 text-uppercase">Partners</h2>
          <p className="mb-4">
            For the last 30 years we have had the pleasure our working with some
            of the biggest and best companies in Australia. Not only have we
            worked with the finest teams in the country but we have also
            developed close contacts and regular suppliers with many of them
            which is a testament to our commitment to produce successful
            outcomes for our customers year after year.
          </p>
        </div>
        <div>
          <h4 className="text-center mb-3">Companies we work with include</h4>
          <div className="row">
            <div className="col-md-6">
              <ul>
                <li>Residential &amp; Commercial Property Developers</li>
                <li>Building &amp; Construction Companies</li>
                <li>Hotels, Resorts &amp; RV Parks</li>
                <li>
                  Private &amp; Public Schools, Collages &amp; Universities
                </li>
                <li>Highrise Apartments and Complexes</li>
                <li>Restaurants and Cafes</li>
                <li>Facility Management Companies</li>
                <li>Theme Parks</li>
                <li>Clubs &amp; Resorts</li>
              </ul>
            </div>
            <div className="col-md-6 mb-4">
              <ul>
                <li>Mining Camps &amp; Facilities</li>
                <li>Swimming Pools</li>
                <li>Local councils projects</li>
                <li>State Government infrastructure</li>
                <li>National Retailers</li>
                <li>Shopping Centres</li>
                <li>Airports</li>
                <li>Major Sporting Facilities.</li>
                <li>Emergency Services Facilities</li>
              </ul>
            </div>
          </div>
          <Partners />
        </div>
      </ContentSection>
    </div>
  );
};

export default Partner;
