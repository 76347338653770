import React, { useEffect } from "react";
import CommonFastField from "components/common/common-fast-field";
import { Form } from "formik";
import { Row, Col, FormGroup } from "reactstrap";
import ContentService from "services/content-service";
import FormikSubmitButton from "components/common/formik-submit-button";
import EnhancedContentSection from "components/common/enhanced-content-section";
import useContactUsHandler from "./hooks/use-effect-handler";
import PageTagging from "components/common/page-tagging/page-tagging";

const ContactUs = ({
  cleanUp,
  contactUs,
  handleSubmit,
  setFieldValue,
  match,
  handleReset,
  resetForm,
}) => {
  const { isLoading, isSuccessful } = contactUs;

  useContactUsHandler(
    match.params.courseId,
    isSuccessful,
    handleReset,
    setFieldValue,
    cleanUp
  );

  useEffect(() => {
    if (isSuccessful) {
      resetForm();
    }
  }, [isSuccessful]);

  return (
    <>
      <EnhancedContentSection
        isBlocking={isLoading}
        showPageTitle={true}
        pageTitle={ContentService().getContentPage("get-in-touch").title}
      >
        <PageTagging title="Horizon Sails &amp; Membranes | Contact Us" />
        {ContentService().getContentPage("get-in-touch").content}

        <Form className="flex-fill mt-3" onSubmit={handleSubmit}>
          <Row>
            <Col xs="12" md="6">
              <CommonFastField
                label="Name"
                accessor="name"
                showLabel
                type="text"
                overridePlaceholder={true}
                showRequiredIndicator={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <CommonFastField
                label="Email Address"
                accessor="email"
                showLabel
                type="text"
                overridePlaceholder={true}
                showRequiredIndicator={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <CommonFastField
                label={`Phone Number`}
                accessor="phone"
                showLabel
                overridePlaceholder={true}
                showRequiredIndicator={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="9">
              <CommonFastField
                label="Message"
                accessor="message"
                showLabel
                type="textarea"
                overridePlaceholder={true}
                showRequiredIndicator={true}
              />
            </Col>
          </Row>

          <FormGroup>
            <FormikSubmitButton
              label="Send"
              isLoading={isLoading}
              className="sw-btn-primary"
              scrollToFirstError={true}
            />
          </FormGroup>
        </Form>

        <h2 className="mt-4 text-uppercase text-center">Our Location</h2>
        <div className="mt-4 text-center">
          <h6>3/8 Centre View Drive, Biggera Waters, QLD 4216</h6>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3525.02389380428!2d153.3804587516559!3d-27.93191274312233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b91102dade771d5%3A0x1ab3e6c9e64f6123!2sUnit%203%2F8%20Centre%20View%20Dr%2C%20Biggera%20Waters%20QLD%204216!5e0!3m2!1sen!2sau!4v1632629766303!5m2!1sen!2sau"
            height="450"
            frameborder="0"
            style={{ border: "0", width: "80%" }}
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
      </EnhancedContentSection>
    </>
  );
};

export default ContactUs;
