/**
 * Http abstraction
 * To encapsulate http provider details like FETCH Api or Axios.
 * Wish we have interface like typescript.
 *
 * Provider: Axios
 * - rcnet @solvable
 */

import axios from "axios";
import Config from "./../../config";

const HttpClient = () => {
  let api = axios.create({ baseURL: Config.apiUrl });

  // ===============
  // Methods
  // ===============

  /**
   *
   * @param {resourcePathName} Resource path name for example 'user'
   * @param {cfg} you can use params - An object parameter { ID: 12345 }
   */
  const get = (resourcePathName, cfg = null) =>
    cfg ? api.get(resourcePathName, cfg) : api.get(resourcePathName);

  const post = (resourcePathName, payload, cfg = null) =>
    cfg
      ? api.post(resourcePathName, payload, cfg)
      : api.post(resourcePathName, payload);

  const del = (urlParams) => api.delete(urlParams);

  const put = (urlParams, payload, cfg = null) =>
    cfg ? api.put(urlParams, payload, cfg) : api.put(urlParams, payload);

  const patch = (resourcePathName, payload) =>
    api.patch(resourcePathName, payload);

  // Note: Has an issue with interceptor
  const setAuthToken = (token) => {
    api.defaults.headers.common["Authorization"] = token;
    return this;
  };

  /**
   * Either form, json etc.
   * @param {type} 'form', 'json'
   * Note: if not in the list of types it will apply as custom
   */
  const setContentType = (type) => {
    // Pre-defined list of content types
    let contentType = getContentTypeString(type);
    api.defaults.headers.post["Content-Type"] = contentType;
    return this;
  };

  /**
   *
   * @param {type} Basic content types are supported as of the moment.
   * Try to contribute by adding new types to enhance the library. thanks :P
   * types: form, json, pdf, xml, zip, csv, plain, html
   */
  const getContentTypeString = (type) => {
    let contentType =
      type === "form"
        ? "application/x-www-form-urlencoded"
        : type === "json"
        ? "application/json"
        : type === "pdf"
        ? "application/pdf"
        : type === "xml"
        ? "application/xml"
        : type === "zip"
        ? "application/zip"
        : type === "csv"
        ? "text/csv"
        : type === "plain"
        ? "text/plain"
        : type === "html"
        ? "text/html"
        : type === "jpg"
        ? "image/jpeg"
        : type === "png"
        ? "image/png"
        : type;
    return contentType;
  };

  /**
   * Will return the specific provider (actual library)
   * Note: Avoid using this!
   */
  const getProvider = () => api;

  return {
    get,
    post,
    put,
    delete: del,
    patch,
    getProvider,
    getContentTypeString,
    setContentType,
    setAuthToken,
  };
};

export default HttpClient;
