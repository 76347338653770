export const partners = [
  { name: "abigroup.jpg" },
  { name: "baulderstone.jpg" },
  { name: "hope-island.jpg" },
  { name: "hutchinson.jpg" },
  { name: "hyperdome.jpg" },
  { name: "jupiters.jpg" },
  { name: "palazzo-versace.jpg" },
  { name: "retail-first.jpg" },
  { name: "sanctuary-cove.jpg" },
  { name: "sheraton-mirage-gold-coast.jpg" },
  { name: "southport-sharks.jpg" },
  { name: "stockland.jpg" },
  { name: "twin-towns.jpg" },
  { name: "village-roadshow.jpg" },
  { name: "westfield.jpg" },
];
