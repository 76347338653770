import React from "react";
import { Link } from "react-router-dom";
import ContentSection from "./../components/common/content-section";
import { corePublicPages } from "./public-content-core";
import { caseStudiesPublicPages } from "./public-content-case-studies";
import ImageHeaderSecondary from "components/common/image-header-secondary";
import ImageGallery from "components/common/image-gallery";

export const publicPages = [
  ...corePublicPages,
  ...caseStudiesPublicPages,
  {
    pageName: "solutions",
    title:
      "Horizon Sails & Membranes | Shade Solutions | Commercial & Residential",
    description:
      "Horizon Sails & Membranes specialise in commercial shade solutions including Shade Sails, PVC Membrane Structures and Resort Umbrellas.",
    noContentSection: true,
    content: (
      <>
        <ImageHeaderSecondary imgSrc="https://solvable.azureedge.net/horizon-sails/images/shade-structure-17.jpg" />
        <ContentSection>
          <h2 className="mb-3 text-center text-uppercase">Horizon Solutions</h2>
          <p className="text-center mb-4">
            Horizon Sails &amp; Membranes specialise in commercial shade
            solutions including Shade Sails, PVC Membrane Structures and Resort
            Umbrellas which provide protection from the harsh Australian
            environment. When you entrust your commercial shade project to
            Horizon Sails &amp; Membranes, you know that your investment is is
            safe, experienced hands.
          </p>
          <div className="row form-row">
            <div className="col-md-4 col-12 text-center mb-3">
              <h4 className="mb-3">Shade Sail Structures</h4>
              <p className="mb-3">
                Commercial steel structure with superior ​protections from the
                elements.
              </p>
              <Link to="/shade-sail-structures/">
                <img
                  src="https://solvable.azureedge.net/horizon-sails/images/shade-structure-17.jpg"
                  alt=""
                />
              </Link>
              <Link
                to="/shade-sail-structures/"
                className="mt-blue-mini-button mt-3 btn btn-secondary"
              >
                Learn More
              </Link>
            </div>
            <div className="col-md-4 col-12 text-center mb-3">
              <h4 className="mb-3">PVC Membranes</h4>
              <p className="mb-3">
                Creative, beautiful PVC membranes for all applications.
              </p>
              <Link to="/pvc-membranes/">
                <img
                  src="https://solvable.azureedge.net/horizon-sails/images/membrane-main.jpg"
                  alt=""
                />
              </Link>
              <Link
                to="/pvc-membranes/"
                className="mt-blue-mini-button mt-3 btn btn-secondary"
              >
                Learn More
              </Link>
            </div>
            <div className="col-md-4 col-12 text-center">
              <h4 className="mb-3">Resort Umbrellas</h4>
              <p className="mb-3">
                Superior quality resort solutions designed to meet your
                requirements.
              </p>
              <Link to="/resort-umbrellas/">
                <img
                  src="https://solvable.azureedge.net/horizon-sails/images/resort-umbrella-main.jpg"
                  alt=""
                />
              </Link>
              <Link
                to="/resort-umbrellas/"
                className="mt-blue-mini-button mt-3 btn btn-secondary"
              >
                Learn More
              </Link>
            </div>
          </div>
        </ContentSection>
      </>
    ),
  },
  {
    pageName: "shade-sail-structures",
    title: "Shade Sail Structures | Sun Protection",
    description:
      "At Horizon Sails & Membranes, we specialise in commercial and industrial applications on projects ranging from Theme Parks and Hotels through to Car parks and Restaurant / cafe solutions.",
    noContentSection: true,
    content: (
      <>
        <ImageHeaderSecondary imgSrc="https://solvable.azureedge.net/horizon-sails/images/shade-structure-17.jpg" />
        <ContentSection>
          <h2 className="mb-5 text-center text-uppercase">
            Shade Sail Structures
          </h2>
          <div className="row">
            <div className="col-md-6 col-12">
              <p>
                A shade sail structure can provide a unique alternative for
                almost any type of application. Further, individually designed
                structures can be used to achieve an ambience not possible with
                conventional building products. Shade sails have become a widely
                used method of providing sun protection over pools, playgrounds
                and patio areas.
              </p>
              <p>
                At Horizon Sails &amp; Membranes, we specialise in commercial
                and industrial applications on projects ranging from Theme Parks
                and Hotels through to Car parks and Restaurant / cafe solutions.
              </p>
              <p>
                Why don't you call us today and chat with our qualified staff?
                They would be more than happy to take a look at your project
                with you.
              </p>
              <Link
                to="/contact-us/"
                className="mt-blue-mini-button mt-3 btn btn-secondary mb-2"
              >
                <i className="fas fa-envelope mr-1" />
                Request a Quote
              </Link>
            </div>
            <div className="col-md-6 col-12 mb-3">
              <img
                src="https://solvable.azureedge.net/horizon-sails/images/carpark-main.jpg"
                alt=""
              />
            </div>
          </div>
        </ContentSection>

        <ContentSection bgClass="sw-bg-std-grey ">
          <h2 className="text-center mb-4 text-uppercase">Image Gallery</h2>
          <ImageGallery
            keyId="shade-sail-structures"
            urls={[
              "https://solvable.azureedge.net/horizon-sails/images/carpark-main.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/shade-structure-image-19.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/shade-structure-image-25v2.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/shade-structure-image-14v2.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/shade-structure-16v2.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/shade-structure-18v2.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/shade-structure-21v2.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/shade-structure-image-1.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/sea-world-2.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/carpark-6.jpg",
            ]}
          />
        </ContentSection>

        <ContentSection>
          <h2 className="mb-4 text-center text-uppercase">
            Featured Materials for Shade Sails
          </h2>
          <p className="mb-4">
            At Horizon Sails &amp; Membranes, we use ONLY the best shade sail
            materials that utilise world leading R&amp;D and manufacturing
            standards in all of our commercial and large residential projects.
          </p>
          <div className="row">
            <div className="col-md-8 col-12">
              <h4>EXTREME 32</h4>
              <ul>
                <li>
                  eXtreme 32 is a heavy duty 320 GSM commercial shade fabric
                  range which consistently delivers enduring strength, lasting
                  durability and excellent shade protection characteristics. (up
                  to 95.8% UV-R Protection.)
                </li>
                <li>
                  eXtreme 32 is designed and engineered for the full spectrum of
                  shade applications from the largest commercial and
                  architectural projects through to domestic installations
                </li>
                <li>15 year warranty</li>
              </ul>
            </div>
            <div className="col-md-4 col-12 mb-5">
              <img
                src="https://solvable.azureedge.net/horizon-sails/images/extreme32.png"
                alt=""
              />
            </div>
            <div className="col-md-8 col-12">
              <h4>Z16</h4>
              <ul>
                <li>
                  As one of Australia’s most popular shade fabric brands, Z16
                  holds an unrivalled reputation for durability, UV-R
                  performance and quality. Designed to reduce glare, heat and
                  provide comfort and protection. Z16 is the perfect choice for
                  your next shade project.
                </li>
                <li>10 year warranty</li>
              </ul>
            </div>
            <div className="col-md-4 col-12 mb-5">
              <img
                src="https://solvable.azureedge.net/horizon-sails/images/z16.png"
                alt=""
              />
            </div>
            <div className="col-md-8 col-12">
              <h4>Com 95 from Gale Pacific</h4>
              <ul>
                <li>
                  With superior UV block, a 10 year full UV degradation
                  warranty, a wide range of colours and over 20 years performing
                  in extreme climates, GALE Pacific’s Commercial 95® remains one
                  of the world’s most popular architectural shade fabrics.
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-12 mb-5">
              <img
                src="https://solvable.azureedge.net/horizon-sails/images/gale.jpg"
                alt=""
              />
            </div>
            <div className="col-md-8 col-12">
              <h4>MONOTEC 370</h4>
              <ul>
                <li>
                  Developed as an &ldquo;install and forget&rdquo; shade cloth,
                  Monotec 370 Series shade cloth is the perfect solution for
                  shade sails and structures. Monotec 370 Series provides
                  consistent protection to people and property in the harsh
                  Australian climate.
                </li>
                <li>
                  Compared to many other fabrics, Monotec 370 will not stretch
                  over time assuring the integrity of your structure’s stability
                  for many years to come.
                </li>
                <li>15 year warranty</li>
              </ul>
            </div>
            <div className="col-md-4 col-12 mb-3 mt-3">
              <img
                src="https://solvable.azureedge.net/horizon-sails/images/monotec370.jpg"
                alt=""
              />
            </div>
          </div>
        </ContentSection>
      </>
    ),
  },
  {
    pageName: "pvc-membranes",
    title: "PVC Membranes | Custom Shade Solutions",
    description:
      "Membrane structures not only provide a waterproof alternative to conventional building products, but also can be designed in such a way that clear weather shields can be incorporated into the design to provide a totally weatherproof area.",
    noContentSection: true,
    content: (
      <>
        <ImageHeaderSecondary imgSrc="https://solvable.azureedge.net/horizon-sails/images/membrane-3.jpg" />
        <ContentSection>
          <h2 className="mb-5 text-center text-uppercase">PVC Membranes</h2>
          <div className="row">
            <div className="col-md-6 col-12">
              <p>
                Membrane structures not only provide a waterproof alternative to
                conventional building products, but also can be designed in such
                a way that clear weather shields can be incorporated into the
                design to provide a totally weatherproof area.
              </p>
              <p>
                These are customized solutions designed to suit the dimensions
                of a particular space and to integrate with the architecture and
                design of the location.
              </p>
              <p>
                Start a conversation with our project managers today that will
                start your next big project of on the right foot.
              </p>
              <Link
                to="/contact-us/"
                className="mt-blue-mini-button mt-3 btn btn-secondary mb-2"
              >
                <i className="fas fa-envelope mr-1" />
                Request a Quote
              </Link>
            </div>
            <div className="col-md-6 col-12 mb-4">
              <img
                src="https://solvable.azureedge.net/horizon-sails/images/membrane-main.jpg"
                alt=""
              />
            </div>
          </div>
        </ContentSection>

        <ContentSection bgClass="sw-bg-std-grey ">
          <h2 className="text-center mb-4 text-uppercase">Image Gallery</h2>
          <ImageGallery
            keyId="pvc-membranes"
            urls={[
              "https://solvable.azureedge.net/horizon-sails/images/membrane-1.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/membrane-2.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/modern-art.jpg",
            ]}
          />
        </ContentSection>

        <ContentSection>
          <h2 className="mb-4 text-center text-uppercase">
            Featured Materials for PVC Membrane Structures
          </h2>
          <p className="mb-5 text-center">
            At Horizon Sails &amp; Membranes we use ONLY the best PVC Membrane
            materials which utilise world leading R&amp;D and manufacturing
            standards in all of our commercial and large residential projects.
          </p>
          <div className="row">
            <div className="col-md-8 col-12">
              <h4>SERGE FERRARI &amp; MEHLER TEXNOLOGIES</h4>
              <p>
                Serge Ferrari of France and Mehler Texnologies of Germany
                manufacture superior quality architectural membrane fabrics.
                Serge Ferrari &amp; Mehler Texnologies fabrics are suitable for
                a wide range of applications from blinds and awnings to shade
                sails, canopies and permanent tensile membrane structures.
              </p>
            </div>
            <div className="col-md-4 col-12 text-center">
              <img
                src="https://solvable.azureedge.net/horizon-sails/images/serge-ferrari.png"
                alt=""
                className="mb-3"
              />
              <img
                src="https://solvable.azureedge.net/horizon-sails/images/mehler.jpg"
                alt=""
              />
            </div>
          </div>
        </ContentSection>
      </>
    ),
  },
  {
    pageName: "resort-umbrellas",
    title: "Resort Umbrellas | Shade Solutions | Commercial & Residential",
    description:
      "Our Resort Umbrellas are an elegant and stylish outdoor solution for shade provision available in a wide range of architectural grade UV stabilised fabric colours.",
    noContentSection: true,
    content: (
      <>
        <ImageHeaderSecondary imgSrc="https://solvable.azureedge.net/horizon-sails/images/resort-umbrella-9.jpg" />
        <ContentSection>
          <h2 className="mb-5 text-center text-uppercase">Resort Umbrellas</h2>
          <div className="row">
            <div className="col-md-6 col-12">
              <p>
                Our Resort Umbrellas are an elegant and stylish outdoor solution
                for shade provision available in a wide range of architectural
                grade UV stabilised fabric colours. We can cater for solutions
                from 2M square to 5M square with either square or hexagonal
                shaped canopies. They can also be supported either by a centre
                column or a fixed or rotating type cantilever column. Any
                combination of the above is possible.
              </p>
              <p>
                If you have been on holiday anywhere in Australia, chances are
                you've sat under our resort umbrellas.
              </p>
              <Link
                to="/contact-us/"
                className="mt-blue-mini-button mt-3 btn btn-secondary mb-2"
              >
                <i className="fas fa-envelope mr-1" />
                Request a Quote
              </Link>
            </div>
            <div className="col-md-6 col-12 mb-4">
              <img
                src="https://solvable.azureedge.net/horizon-sails/images/resort-umbrella-main.jpg"
                alt=""
              />
            </div>
          </div>
        </ContentSection>
        <ContentSection bgClass="sw-bg-std-grey ">
          <h2 className="text-center mb-4 text-uppercase">Image Gallery</h2>
          <ImageGallery
            keyId="resort-umbrellas"
            urls={[
              "https://solvable.azureedge.net/horizon-sails/images/resort-umbrella-1.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/resort-umbrella-2.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/resort-umbrella-4.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/resort-umbrella-5.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/resort-umbrella-6.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/resort-umbrella-8.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/resort-umbrella-9.jpg",
              "https://solvable.azureedge.net/horizon-sails/images/resort-umbrella-10.jpg",
            ]}
          />
        </ContentSection>
      </>
    ),
  },
  {
    pageName: "new-page",
    title: "New Page",
    description: "",
    content: (
      <>
        <h1>New Page</h1>
      </>
    ),
  },
  {
    pageName: "new-page-2",
    title: "New Page",
    description: "",
    noContentSection: true,
    content: (
      <>
        <ContentSection>
          <h1>Section 1</h1>
        </ContentSection>
        <ContentSection bgClass="sw-bg-std-grey ">
          <h1>Section 2</h1>
        </ContentSection>
        <ContentSection>
          <h1>Section 3</h1>
        </ContentSection>
      </>
    ),
  },
];
