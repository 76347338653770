export const navLinks = [
  {
    title: "Home",
    href: "/",
    subLinks: [],
  },
  {
    title: "Solutions",
    href: "/solutions/",
    subLinks: [
      {
        title: "Shade Sail Structures",
        href: "/shade-sail-structures/",
      },
      {
        title: "PVC Membranes",
        href: "/pvc-membranes/",
      },
      {
        title: "Resort Umbrellas",
        href: "/resort-umbrellas/",
      },
    ],
  },
  {
    title: "Gallery",
    href: "/gallery/",
    subLinks: [],
  },
  // {
  //   title: "Case Studies",
  //   href: "/case-studies/",
  //   subLinks: [
  //     {
  //       title: "My Cubby House",
  //       href: "/case-studies/my-cubby-house/",
  //     },
  //     {
  //       title: "Georges Paragon Resturant",
  //       href: "/case-studies/georges-paragon-resturant/",
  //     },
  //     {
  //       title: "Marina Mirage",
  //       href: "/case-studies/marina-mirage/",
  //     },
  //     {
  //       title: "IGA Supermarket",
  //       href: "/case-studies/iga-supermarket/",
  //     },
  //     {
  //       title: "Sanctuary Cove Umbrellas",
  //       href: "/case-studies/sanctuary-cove-umbrellas/",
  //     },
  //     {
  //       title: "Southport Yacht Club",
  //       href: "/case-studies/southport-yacht-club/",
  //     },
  //   ],
  // },
  {
    title: "Contact",
    href: "/contact-us/",
    subLinks: [],
  },
];
