import React from "react";
import NavBar from "./../components/nav-bar";
import Footer from "./../components/footer";
import ModuleRoutes from "./../routes/routing";
import { Route, Switch, Redirect } from "react-router-dom";
import { navLinks } from "../constants/navigation";
import "./full-layout.scss";

const FullLayout = () => {
  return (
    <>
      <NavBar navLinks={navLinks} />

      <main role="main">
        <Switch>
          {ModuleRoutes.map((prop, key) => {
            if (prop.navlabel) {
              return null;
            } else if (prop.collapse) {
              return prop.child.map((prop2, key2) => {
                if (prop2.collapse) {
                  return prop2.subchild.map((prop3, key3) => {
                    return (
                      <Route
                        exact
                        path={prop3.path}
                        component={prop3.component}
                        key={key3}
                      />
                    );
                  });
                }
                return (
                  <Route
                    exact
                    path={prop2.path}
                    component={prop2.component}
                    key={key2}
                  />
                );
              });
            } else if (prop.redirect) {
              return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
            } else {
              return (
                <Route
                  exact
                  path={prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            }
          })}
        </Switch>
      </main>

      <Footer />
    </>
  );
};

export default FullLayout;
