import React from "react";
import CaseStudy from "components/case-study/case-study";
import ImageHeaderSecondary from "components/common/image-header-secondary";
import { Link } from "react-router-dom";
import ContentSection from "../components/common/content-section";

export const caseStudiesPublicPages = [
  {
    pageName: "case-studies",
    title: "Case Studies",
    description: "",
    noContentSection: true,
    content: (
      <>
        <ImageHeaderSecondary imgSrc="https://solvable.azureedge.net/horizon-sails/images/carpark-iga3.jpg" />
        <ContentSection>
          <h2 className="mb-3 text-center text-uppercase">
            Horizon Case Studies
          </h2>
          <p>
            For over 30 years, we've been building superior shade structures for
            our clients, which stand the test of time. With over 3000 successful
            projects under our belt we know how to deliver the perfect project
            for your company or clients.
          </p>
          <p className="mb-4">
            It's been our team's experience which has lead us to become
            Australia's longest running commercial shade sail company. This
            experience counts for something as it help you and your team to
            deliver successful projects for your company or your clients.
          </p>
          <h4 className="text-center mb-4">
            Follow the links to our case studies
          </h4>
          <div className="row form-row">
            <div className="col-md-4 col-12 text-center mb-3">
              <h4 className="mb-1">My Cubby House</h4>
              <Link to="my-cubby-house">
                <img
                  src="https://solvable.azureedge.net/horizon-sails/images/shade-structure-23.jpg"
                  alt=""
                />
              </Link>
            </div>
            <div className="col-md-4 col-12 text-center mb-3">
              <h4 className="mb-1">​Paragon Restaurant</h4>
              <Link to="georges-paragon-resturant">
                <img
                  src="https://solvable.azureedge.net/horizon-sails/images/shade-structure-5.jpg"
                  alt=""
                />
              </Link>
            </div>
            <div className="col-md-4 col-12 text-center mb-3">
              <h4 className="mb-1">Marina Mirage</h4>
              <Link to="marina-mirage">
                <img
                  src="https://solvable.azureedge.net/horizon-sails/images/membrane-main.jpg"
                  alt=""
                />
              </Link>
            </div>
            <div className="col-md-4 col-12 text-center mb-3">
              <h4 className="mb-1">​IGA Supermarket</h4>
              <Link to="iga-supermarket">
                <img
                  src="https://solvable.azureedge.net/horizon-sails/images/carpark-iga.jpg"
                  alt=""
                />
              </Link>
            </div>
            <div className="col-md-4 col-12 text-center mb-3">
              <h4 className="mb-1">Sanctuary Cove</h4>
              <Link to="sanctuary-cove-umbrellas">
                <img
                  src="https://solvable.azureedge.net/horizon-sails/images/black-umbrella-2.jpg"
                  alt=""
                />
              </Link>
            </div>
            <div className="col-md-4 col-12 text-center mb-3">
              <h4 className="mb-1">Southport Yacht Club</h4>
              <Link to="southport-yacht-club">
                <img
                  src="https://solvable.azureedge.net/horizon-sails/images/shade-structure-17.jpg"
                  alt=""
                />
              </Link>
            </div>
          </div>
        </ContentSection>
      </>
    ),
  },

  {
    pageName: "case-studies/my-cubby-house",
    title: "My Cubby House",
    description: "",
    noContentSection: true,
    content: (
      <>
        <CaseStudy
          heading="My Cubby House"
          mainImage="https://solvable.azureedge.net/horizon-sails/images/my-cubby-house.jpg"
          imageGalleryUrls={[
            "https://solvable.azureedge.net/horizon-sails/images/my-cubby-house-4.jpg",
            "https://solvable.azureedge.net/horizon-sails/images/my-cubby-house-shade-2.jpg",
            "https://solvable.azureedge.net/horizon-sails/images/my-cubby-house-1.jpg",
            "https://solvable.azureedge.net/horizon-sails/images/shade-structure-2.jpg",
            "https://solvable.azureedge.net/horizon-sails/images/my-cubby-house-2.jpg",
          ]}
        >
          <h2>Shade Structures for Childcare Centers</h2>

          <p className="mb-3">
            We often get asked by childcare centres for shade solutions that are
            modern, functional and long lasting as childcare centres,
            particularly those on the Gold Coast, Brisbane and sunshine coast
            have very high UV protection needs.
          </p>
          <strong>The Main Issues</strong>
          <p className="mb-3">
            My Cubby House in Southport on the Gold Coast, has a lot of outdoor
            space to cover and were looking for an attractive, functional and
            affordable alternative to the traditional pergola or expensive hard
            roof solution.
          </p>
          <p className="mb-3">
            When they approached us Nick, our General manager visited the client
            at the site so he could develop the right solution for them. Right
            away Nick could see that in order to protect the children and the
            staff from Queensland's harsh and changing climate that the centre
            needed a steel and PVC membrane structure that would keep everyone
            protected but would also stand the test of time.
          </p>
          <strong>Shade Structure Design</strong>
          <p>
            After the design team had completed several designs for My cubby
            House, Nick was back on site, showing the clients the design and CAD
            drawings, showing exactly what the shade structures would look like
            and how they would protect the children and staff from the elements.
          </p>
          <strong>Shade Structure Manufacture</strong>
          <p className="mb-3">
            Soon after our manufacturing team began constructing the steel work
            for the shade structure and the PVC membrane at our factory and
            headquarters in Molendiner on the Gold Coast. Unlike many other
            shade sail companies in Australia, we build everything in house from
            scratch, which really helps us to manage every project personally
            without costly mistakes and compromises on quality. Everyday we meet
            with our in house team and go over the day's projects. Over the last
            30 years they've become a very tight and competent team of
            professionals.
          </p>
          <strong>Shade Structure Installation</strong>
          <p className="mb-3">
            Instalation day is always exciting for us and the project at my
            Cubby House in Southport was no exception. Our steel team got to
            work first on the steel structure, first with the footings, the
            steel and then the cables. After a few days the membrane was
            delivered on site and the Horizon Sails &amp; Membranes team got to
            work putting PVC membrane in place and then moved on to make the
            final touches the the structure.
          </p>
          <strong>Project Manager's comments (Nick Emorfopoulos)</strong>
          <p className="mb-3">
            Completing a job is always satisfying to the whole team here at
            Horizon Sails &amp; Membranes. This job in particular required our
            design team to create structures which would complement the
            building. The client wanted them to blend in and be part of the
            overall building and as you can see from the photos, the job came up
            a treat.
          </p>
          <strong>Client's Testimonial</strong>
          <p className="mb-3">
            From the moment we spoke with Nick from Horizon Sails and structures
            we were impressed with his ability to interpret&nbsp;what we needed.
            This jobs was about the kids and staff but we also wanted the
            building to be beautiful. The PVC and the steel really&nbsp;did the
            job well and it completed our beautiful building.&nbsp;
          </p>
          <p className="mb-5">
            <strong>
              Many thanks to Nick and the boys at Horizon Sails and Structures.
            </strong>
          </p>
        </CaseStudy>
      </>
    ),
  },
  {
    pageName: "case-studies/georges-paragon-resturant",
    title: "Georges Paragon Resturant",
    description: "",
    noContentSection: true,
    content: (
      <>
        <CaseStudy
          heading="Georges Paragon Resturant"
          mainImage="https://solvable.azureedge.net/horizon-sails/images/georges-paragon-restaurant-2.jpg"
          imageGalleryUrls={[
            "https://solvable.azureedge.net/horizon-sails/images/georges-paragon-restaurant-2.jpg",
            "https://solvable.azureedge.net/horizon-sails/images/georges-paragon-restaurant-3.jpg",
            "https://solvable.azureedge.net/horizon-sails/images/georges-paragon-restaurant-5.jpg",
            "https://solvable.azureedge.net/horizon-sails/images/georges-paragon-restaurant-7.jpg",
          ]}
        >
          <h2>Shade Structures for Restaurants</h2>
          <p className="mb-3">
            George Tasis (the founder and owner of George's Paragon
            Restaurant's) has been a friend and loyal customer of Horizon Sails
            and Membranes since 2004. Over the years George has commissioned
            some amazing architectural membranes and shade sails to compliment
            his Greek/Mediterranean themed restaurant. Like his delicious food,
            George will settle for nothing short of the best and Horizon has
            been providing him the best for over 13 years.
          </p>
          <strong>The Main Issues</strong>
          <p className="mb-3">
            As George's Paragon Sanctuary Cove is right on the waterfront George
            needed something practical to shelter his clients but also needed
            something in keeping with the water and the restaurants nautical
            theme. ARCHITECTURAL MEMBRANES AND SHADE SAILS WERE THE PERFECT
            SOLUTION!
          </p>
          <strong>Shade Structure Design</strong>
          <p className="mb-3">
            With the nautical theme being at the forefront of Horizons scope our
            engineers and draftsman have over the years worked at increasing
            waterproof coverage and shaded areas for George. The end result is
            what you see today. Multiple waterproof and shaded area, all
            uniquely designed and sculpted to suit the restaurant decor.
          </p>
          <strong>Shade Structure Manufacture</strong>
          <p className="mb-3">
            The the sails displayed at George's Paragon Sanctuary Cove have been
            hand fabricated at the Horizon factory in Molendinar on the Gold
            Coast. Each sail have been precisely measured and patterned to
            ensure a perfect fit for its desired location. The end result has
            been outdoor dining on the water with the comfort and reliability of
            a waterproof shelter. Brilliant
          </p>
          <strong>Shade Structure Installation</strong>
          <p className="mb-3">
            Each time George's picks up the phone to call Horizon the boys know
            a good feed is in store. George's Paragon Sanctuary Cove would
            definitely have to rate high on the list of 'favourite spots to
            work' for the Horizon team as every project comes with complimentary
            seafood platters for lunch and delicious Greek deserts!
          </p>
          <strong>Project Manager's comments (Nick Emorfopoulos)</strong>
          <p className="mb-3">
            Sanctuary cove is one of the Australia's most iconic boating,
            entertainment and residential communities, built on the Gold Coast
            the venue hosts multiple boast shows, has amazing restaurants, a
            world class golf course and multi-milion dollar homes. In order for
            George to continue to attract high end guests his food and the
            restaurant is always at the highest standard. The sail structures we
            erect and maintain at the restaurant reflect George's high standards
            and as always it's a pleasure to look after George &amp; his team.
          </p>
          <strong>Client's Testimonial</strong>
          <p className="mb-3">
            Our restaurants are so important to us so we won't just trust anyone
            with their care. Krishna, Nick and the team from Horizon sails have
            always looked after us with the design, construction and
            installation of our sails and their service has always been
            impeccable
          </p>
          <p className="mb-4">
            <strong>
              George Tasis
              <br />
              Georges Paragon Restaurants
              <br />
              Sanctuary Cove, Mt Tambourine. Coolangatta, Brisbane
            </strong>
          </p>
        </CaseStudy>
      </>
    ),
  },
  {
    pageName: "case-studies/marina-mirage",
    title: "Marina Mirage",
    description: "",
    noContentSection: true,
    content: (
      <>
        <CaseStudy
          heading="Marina Mirage"
          mainImage="https://solvable.azureedge.net/horizon-sails/images/membrane-2.jpg"
          imageGalleryUrls={[
            "https://solvable.azureedge.net/horizon-sails/images/membrane-2.jpg",
            "https://solvable.azureedge.net/horizon-sails/images/membrane-1.jpg",
          ]}
        >
          <h2>PVC Membrane Dome Roofing Project</h2>
          <p className="mb-3">
            Marina Mirage, Gold Coast has been a long standing client for
            Horizon Sails &amp; Membrane. We have not only completed projects
            for the centre itself but have designed and completed many
            architectural shade sails for Marina Mirage tenants who live in many
            of the attractive, high end apartments and townhouses.
          </p>
          <strong>The Main Issues</strong>
          <p className="mb-3">
            Marina Mirage were in need of a re-skin on the existing dome
            structure as the previous fabric was getting to the end of its life
            and Marina Mirage management wanted to keep up the resorts look and
            appeal. Over the years Horizon Sails &amp; Membranes has had much
            experience in covering all shapes of structures including domes, so
            Marina Mirage chose the Horizon team for the project.
          </p>
          <strong>Shade Structure Design</strong>
          <p className="mb-3">
            With every dome structure there is extensive patterning to be
            undertaken to ensure the new fabric fits like a glove. The new skin
            has to be 100% watertight with zero creases. After a few days of
            patterning the sail design was ready for fabrication. Thanks to the
            drafting team we were completely confident that the sail would fit
            and we could ensure no water or wind would leak into the building.
          </p>
          <strong>Shade Structure Manufacture</strong>
          <p className="mb-3">
            This particular sail took over a week to fabricate as the sail had
            to be cut into exact wedge slices. All wedges were then high
            frequency welded together until the exact shape was created for the
            dome. The floor space which which we have in our state of the art
            manufacturing facility on the Goal coast ensured that we could lay
            out each piece of the sail and have it made for a perfect fit when
            it came to instalation day on the project.
          </p>
          <strong>Shade Structure Installation</strong>
          <p className="mb-3">
            For a structure of this size we need more than just man power. A
            crane was used to hoist the sail onto the roof and lift the sail
            into place. It took a team of 4 men to lower the sail over the steel
            shell and secure the fixings. On a job like this we had to ensure
            that there was no wind that day as any slight gust would make the
            sail much more difficult to secure.
          </p>
          <strong>Project Manager's comments (Nick Emorfopoulos)</strong>
          <p className="mb-3">
            Our team love working on these creative jobs, where it's more than
            just a shade sail in a carpark. Working on the Marina Mirage project
            was challenging and rewarding and the results speak for themselves.
            Standing under the done and looking up to the top of the dome is an
            experience in itself. It's beautiful piece of architecture and we're
            proud to play our part in the whole Marina Mirage project.
          </p>
          <strong>Client's Testimonial</strong>
          <p className="mb-3">
            The Dome at Marina Mirage is an iconic part of the Gold Coast
            shopping precinct. We needed a company who could confidently manage
            a project of this size without "dropping the ball". For us, Horizon
            Sail Structures was an sound investment. Not only could the manage a
            project of this size but they could do it well, in fact they
            exceeded our expectations.
          </p>
          <p className="mb-4">
            <strong>Mark Selvey – Center Management</strong>
          </p>
        </CaseStudy>
      </>
    ),
  },
  {
    pageName: "case-studies/iga-supermarket",
    title: "IGA Supermarket",
    description: "",
    noContentSection: true,
    content: (
      <>
        <CaseStudy
          heading="IGA Gladstone – Shopping Centre Car Park."
          mainImage="https://solvable.azureedge.net/horizon-sails/images/iga-carpark.jpg"
          imageGalleryUrls={[
            "https://solvable.azureedge.net/horizon-sails/images/carpark-iga3.jpg",
            "https://solvable.azureedge.net/horizon-sails/images/carpark-iga.jpg",
          ]}
        >
          <h2>Supermarket Carpark Shade Sail Project</h2>
          <p className="mb-3">
            For this particular project Horizon Sails &amp; Membranes were
            engaged by IGA to design and install shade sails for their car park.
            This project was a little different to our standard shopping centre
            car park design as we were working in a semi-cyclonic sub-tropical
            region of Queensland.
          </p>
          <strong>The Main Issues</strong>
          <p className="mb-3">
            Due to the fact that we were working in a semi-cyclonic region of
            Queensland we had to ensure the shade fabric used was 100% suitable
            for purpose. We needed a light weight fabric that would allow gale
            winds to pass through while being heavy enough to withstand the
            strain and pelting from the wind. It was decided that Monotec 370
            would be best suited for the job. This fabric is a high grade
            commercial shade cloth with proven results in similar regions
            (Airlie Beach, Cairns, Townsville, Bundaberg). Being a “top shelf”
            product the fabric came with a 15 year warranty, an aspect very
            important to our client.
          </p>
          <strong>Shade Structure Design</strong>
          <p className="mb-3">
            During the design stage of this project Horizon was guided by our
            engineer on what steel would suit best for the region. In the end
            the design of the structure maintained its standard “T” frame look,
            however the steel thickness was “beefed” up as well as the 316
            stainless steel bolts and connection plates.
          </p>
          <strong>Shade Structure Manufacture</strong>
          <p className="mb-3">
            Horizon had both sails and steel frames fabricated in-house inside
            our 950-square meter factory. Like all projects, QA was paramount
            and all frames, plates and cleats were inspected before they were
            transported up north to Gladstone.
          </p>
          <strong>Shade Structure Installation</strong>
          <p className="mb-3">
            As this project was quite a distance from the Gold Coast (our home
            base) a team of installation specialist from Horizon were sent up
            north to complete the install from start to finish. The day the team
            left for Gladstone we could have sworn we saw a few fishing rods
            hanging out the back of the trucks!
          </p>
          <strong>Client's Testimonial</strong>
          <p className="mb-3">
            As always the guys (at Horizons Sails &amp; Membranes) carried out
            the job in a professional manner. The new carpark at IGA at
            Gladstone looks great...
          </p>
        </CaseStudy>
      </>
    ),
  },
  {
    pageName: "case-studies/sanctuary-cove-umbrellas",
    title: "Sanctuary Cove",
    description: "",
    noContentSection: true,
    content: (
      <>
        <CaseStudy
          heading="Sanctuary Cove Umbrellas"
          mainImage="https://solvable.azureedge.net/horizon-sails/images/resort-umbrella-2.jpg"
          imageGalleryUrls={[
            "https://solvable.azureedge.net/horizon-sails/images/resort-umbrella-2.jpg",
            "https://solvable.azureedge.net/horizon-sails/images/black-umbrella-1.jpg",
          ]}
        >
          <h2>Sanctuary Cove Premium Umbrella Project</h2>
          <p className="mb-3">
            This particular client engaged Horizon Sails &amp; Membranes as they
            were after something a little different to the standard residential
            umbrella. Being that this umbrella was to be positioned at the front
            of the house the client wanted something to “pop” with style.
            Horizon knew just what they needed!
          </p>
          <strong>The Main Issues</strong>
          <p className="mb-3">
            This beautiful multi story home in Sanctuary Cove had many outdoor
            entertaining, some of which Horizon had already installed shade sail
            for. The front of the house however was left rather open and the
            clients felt they needed something to living the front aspect to the
            house.
          </p>
          <strong>Shade Structure Design</strong>
          <p className="mb-3">
            Traditional resort style umbrellas as of a square or rectangle shape
            (as you see in many restaurants). In this situation, the client
            wanted something to “pop” with style and present a certain level of
            unique design. At this point the client had to say nothing more as
            Horizon proposed a black on black hexagonal umbrella on a custom one
            off design specification.
          </p>
          <strong>Shade Structure Manufacture</strong>
          <p className="mb-3">
            Being that this was a custom design Horizon engaged their engineer
            and draftsman with the intention of bringing the 2D model to life!
            From a manufactural and materials point of view the client wanted
            nothing short of the best. With this said Horizon had the steel
            framing hot dipped galvanized and Triplex coated with high gloss
            black finish. The sail was made of Monotec 370 commercial grade
            shade fabric with 316 Australian stainless steel fixings , the same
            spec fabric used on large scale shopping centre car parks. This sail
            was truly going to be the best of the best.
          </p>
          <strong>Shade Structure Installation</strong>
          <p className="mb-3">
            The installation of this umbrella went off without a hitch. The
            footing had been excavated the week before with the steel cage set
            in commercial 32mpa concrete. Once dried, the steel frame and canopy
            were erected.
          </p>
          <strong>Project Manager's comments</strong>
          <p className="mb-3">
            This project ran very smoothly and the client was very impressed
            with the end result, so much so that he immediately ordered more
            umbrellas for other places on his property. John was a pleasure to
            work with.
          </p>
          <strong>Client's Testimonial</strong>
          <p className="mb-3">
            Thanks again for the great work the team at Horizon Sails &amp;
            Membranes have completed on our Sanctuary cove property. ​They just
            look fantastic!
          </p>
          <p className="mb-4">
            <strong>John Baines - Sanctuary Cove, Qld</strong>
          </p>
        </CaseStudy>
      </>
    ),
  },
  {
    pageName: "case-studies/southport-yacht-club",
    title: "Southport Yacht Club",
    description: "",
    noContentSection: true,
    content: (
      <>
        <CaseStudy
          heading="Southport Yacht Club"
          mainImage="https://solvable.azureedge.net/horizon-sails/images/shade-structure-17.jpg"
          imageGalleryUrls={[
            "https://solvable.azureedge.net/horizon-sails/images/shade-structure-17.jpg",
            "/images/",
          ]}
        >
          <h2>Southport Yacht Club PVC Membrane Project</h2>
          <p className="mb-3">
            For this unique project Horizon Sails &amp; Membranes were engaged
            by SYC to come up with a nautical themed structure in keeping with
            the 'boating theme. The club wanted a waterproof solution without
            the use of a hard roof structure.
          </p>
          <strong>The Main Issues</strong>
          <p className="mb-3">
            Being on the waterfront SYC required a strong structure that could
            handle the full brunt of the broad water winds. The club want
            something strength without having to compromise of looks and shape.
          </p>
          <strong>Shade Structure Design</strong>
          <p className="mb-3">
            After many discussions with the club committee a 'barrel wave'
            design was selected so to keep in line with the clubs nautical theme
            and decor.
          </p>
          <strong>Shade Structure Manufacture</strong>
          <p className="mb-3">
            Horizon had both sails and steel frames fabricated in-house under
            the close supervision of our steel and sail fabrication managers.
            Each cleat, plate and mild steel beam was checked for QA before it
            was sent to be 'Hot Dipped Galvanized' in Brisbane. Each section of
            steel was to be 'Hot Dipped Galvanized' to ensure full corrosion and
            rust protection.
          </p>
          <strong>Shade Structure Installation</strong>
          <p className="mb-3">
            Firstly Horizon had to excavate the engineered footings. Being on
            water level these footings were dug by a vacuum dig truck to a
            substantial depth. Reinforcement cages we then placed in the
            footings and concrete was poured. Once the footings had dried the
            installation of the structure began. The installation of the steel
            and sail took the good part of a week. The steel was installed by a
            team of 4 men over 2 days with the sail being installed over the
            course of a full day.
          </p>
          <strong>Project Manager's comments</strong>
          <p className="mb-3">
            It's always enjoyable working at Southport Yacht Club. The project
            really looks great against the water and the members love the new
            addition to the club.
          </p>
          <strong>Client's Testimonial</strong>
          <p className="mb-3">
            Brett James (General Manager of SYC). "Wow, what a structure, it is
            exactly what we were after. It looks just like a wave! The job was
            completed in 2011.
          </p>
        </CaseStudy>
      </>
    ),
  },
];
